/* #pdf-container {
  width: fit-content !important;
  min-height: 100vh;
  position: relative;
}

.react-pdf__Document {
  width: fit-content !important;
}

#pdf-outer-container {
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2); */
/* }


.pinn {
  width: 20px;
  position: absolute;
  transform: translate(-50%, -50%) rotate(280deg);
  z-index: 100;
} */
.e-pv-toolbar.e-control.e-toolbar.e-lib.e-pv-toolbar-scroll.e-keyboard.e-toolpop,
.e-toolbar-item.e-pv-zoom-drop-down-container.e-template.e-popup-text,
#container_annotation_toolbar>.e-toolbar-items.e-tbar-pos>.e-toolbar-left,
.e-toolbar-item.e-pv-undo-container.e-popup-text,
.e-toolbar-item.e-pv-open-document-container.e-popup-text,
.e-toolbar-item.e-pv-submit.e-template.e-popup-text.e-overlay,
.e-toolbar-item.e-pv-annotation-container.e-popup-text,
.e-toolbar-item.e-pv-formdesigner-container.e-popup-text.e-overlay,
.e-toolbar-item.e-pv-print-document-container.e-popup-text,
.e-toolbar-item.e-pv-download-document-container.e-popup-text,
.e-pv-sidebar-toolbar-splitter.e-left,
#container_sideBarToolbar.e-pv-sidebar-toolbar,
.e-toolbar-item.e-pv-pan-separator-container.e-separator,
.e-toolbar-item.e-pv-undo-separator-container.e-separator,
.e-toolbar-item.e-pv-magnification-separator-container.e-separator,
.e-toolbar-item.e-pv-pan-tool-container.e-popup-text,
.e-toolbar-item.e-pv-undo-container.e-popup-text.e-overlay,
.e-toolbar-item.e-pv-redo-container.e-popup-text.e-overlay,
.e-toolbar-item.e-pv-text-select-tool-container.e-popup-text,
.e-toolbar-item.e-pv-zoom-out-container.e-popup-text.e-overlay,
.e-toolbar-item.e-pv-zoom-in-container.e-popup-text.e-overlay {
  display: none !important;
}

.e-pv-comment-icon.e-pv-icon.e-icon-left::before {
  content: "\e945" !important;
  color: #000 !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}