@import "src/styles/variables";

.p-d-f-search {
  display: flex;
  box-shadow: 0px 0px 10px #00263e14;
  border: 1px solid #e0e6eb;
  border-radius: 5px;
  width: 350px;
  padding: 0.5rem;
  background-color: white;
  z-index: 1;
  position: relative;
  left: 250px;
  input {
    border: none;
    width: 100%;
    &:hover,
    &:focus {
      outline: none;
    }
  }

  .tool__container {
    display: flex;
  }

  .page-display {
    padding: 0 8px;
    width: 100px; 
    display: flex;
    align-items: center;
  }
}

.rpv-core__icon {
    color: #16161D;
}