.table-title__container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 500;
    color: #00263e;
  }

  margin: 1rem 0;

  .table-title__container--btn-list {
    .button-component {
      width: 38px;
      height: 38px;
      margin-top: 0;
    }

    .ant-btn-default {
      color: #00619a;
    }
  }

  .table-add__btn {
    .button-component {
      width: 38px;
      height: 38px;
      margin-top: 0;

      .ant-btn {
        color: #fff;
        padding-left: 0.725rem;
      }
    }
  }
}

.table-container {
  width: 100%;
  height: fit-content;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .ant-table-thead {
    .ant-table-cell {
      font-size: 12px;
      background-color: #0a5682;
      color: white;
      white-space: nowrap;
    }

    .ant-table-cell.ant-table-column-has-sorters:hover {
      background-color: #0a5682;
    }
  }

  .ant-table-cell {
    text-transform: capitalize;
  }

  p {
    margin: 0;
  }

  table {
    background-color: #fff;


    .ant-table-tbody {
      tr {
        .ant-table-cell {
          width: fit-content;
          white-space: nowrap;
        }
      }
    }
  }
}

.table-details--route {
  cursor: pointer;
}

.sort-remover {
  .ant-spin-nested-loading {
    .ant-spin-container {
      .ant-table {
        .ant-table-container {
          .ant-table-content {
            table {

              .ant-table-thead {
                tr {
                  .ant-table-column-has-sorters {
                    pointer-events: none;

                    .ant-table-filter-column {
                      justify-content: flex-start;

                      .ant-table-column-title {
                        flex: none;

                        .ant-table-column-sorters {
                          .ant-table-column-sorter {
                            display: none;
                          }
                        }
                      }

                      .ant-dropdown-trigger.ant-table-filter-trigger {
                        pointer-events: all;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.ant-table-content {
  &::-webkit-scrollbar {
    display: none;
  }
}

.row-clickable {
  .ant-spin-nested-loading {
    .ant-spin-container {
      .ant-table {
        .ant-table-container {
          .ant-table-content {
            table {

              .ant-table-tbody {
                tr {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}

tr.ant-table-expanded-row>td,
tr.ant-table-expanded-row:hover>td {
  padding: 0 !important;
}