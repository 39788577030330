.search-input {
  padding-left: 25px;
  width: 36px;
  height: 38px;
  border: 1px solid #00619a;
  outline: none;
  transition-duration: 500ms;
  cursor: pointer;
  background-image: url("../../../assets/icons/search.svg");
  background-repeat: no-repeat;
  background-position-x: 0.4rem;
  background-position-y: 0.5rem;
  box-shadow: none;
  border-radius: 6px;
}

.search-input:focus {
  width: 15rem;
  cursor: text;
  padding-left: 40px;
  color: #a0a0a0;
  background-position-x: 0.4rem;
  background-position-y: 0.5rem;
}