@import "src/styles/variables";

.button-component {
  border-radius: 6px !important;
  border: unset !important;
  width: 100%;
  height: 40px;
  margin-top: 3%;

  button {
    height: 100%;
    border-radius: 6px !important;
    width: 100%;

    span {
      margin: auto 0;
    }
  }
}

.ant-btn-primary {
  background: $primary-color !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  border: none !important;
}

.ant-btn-link {
  background: $dark-grey !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  color: white !important;
}

.ant-btn-dangerous {
  background: $danger-color !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  color: white !important;
}

.btn__flex>span {
  display: flex !important;
  justify-content: space-between !important;
}