.document-details__container {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  background: white;

  .document-details--header {
    width: 100%;

    .ant-collapse {
      height: 100%;
      background: #ffffff;
      box-shadow: 0px 0px 10px #00263e14;
      border: 1px solid #e0e6eb;

      .ant-collapse-item {
        border: 0;

        .ant-collapse-header {
          height: 62px;
          padding: 1rem;
          height: 100%;
          font-size: 20px;
          color: #00263e;
          pointer-events: none;

          .anticon {
            font-size: 16px;
            pointer-events: all;
          }

          .ant-collapse-extra {
            font-size: 14px;
            color: #778899;
            pointer-events: all;

            &::before {
              content: "View ";
            }
          }
        }

        &.ant-collapse-item-active {
          .ant-collapse-header {
            .ant-collapse-extra::before {
              content: "Hide ";
            }
          }
        }

        .ant-collapse-content {
          width: 100%;
          height: 72px;
          position: absolute;
          top: 60px;
          left: 0;
          z-index: 2;
          background-color: #fff;

          .ant-collapse-content-box {
            height: 100%;
            border-bottom: 1px solid #e0e6eb;
          }

          &.ant-collapse-content-active {
            animation: slideDocumentHeaderPanelDown 400ms forwards;
          }

          &.ant-collapse-content-inactive {
            animation: slideDocumentHeaderPanelUp 400ms forwards;
          }
        }
      }
    }
  }

  .document-details--body {
    width: 100%;
    height: calc(100vh - 62px);

    .document-details--body-actions-wrapper {
      width: 100%;
      box-shadow: 0px 0px 10px #00263e14;

    }
    
    .document-details--body-actions {
      padding: 1rem;
      height: 60px;
      display: flex;
      align-items: center;
      background: #f1f3f5;
      box-shadow: 0px 0px 10px #00263e14;
      border: 1px solid #e0e6eb;
      border-bottom: none;

      .document-details--body-actions-buttons {
        position: relative;
        left: 370px;
      }
      .button-component {
        margin: 0;
        width: fit-content;
        height: 32px;
      }

      .ant-row {
        .button-component {
          margin-left: 1rem;

          button {
            font-size: 15px;
            color: #778899;
          }

          button:hover {
            border-color: #00629b;
            color: #00629b;
          }
        }
      }
    }

    .document-details--body-actions-tab {
      padding: 1rem;
      // padding-bottom: none;
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #f1f3f5;
      border: 1px solid #e0e6eb;
      border-top: none;
      
      .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more {
        position: relative;
        padding: 8px 16px;
        background: transparent;
        border: 0;
        display: none;
      }
    }


    .document-details--body-document {
      width: 100%;
      height: calc(100vh - 60px - 63px);
      overflow-y: scroll;

      &.toolbar-less {
        height: calc(100vh - 63px);
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .document-details--body-keyterms-toolbar {
      height: 100%;
      overflow-y: scroll;
      box-shadow: 0px 0px 10px #00263e14;
      border-left: 1px solid #e0e6eb;

      &::-webkit-scrollbar {
        display: none;
      }

      .document-details--body-keyterms-toolbar--title {
        padding: 0.5rem;
        margin: 0;
        width: 100%;
        height: 40px;
        color: #fff;
        font-weight: bold;
        text-align: center;
        background-color: #0a5682;
      }

      .ant-input-affix-wrapper {
        margin: 1rem;
        width: calc(100% - 2rem);
        border-radius: 4px;
      }

      .ant-collapse {
        .ant-collapse-item {
          &.ant-collapse-item-disabled {

            .ant-collapse-header,
            .ant-collapse-header>.arrow {
              cursor: auto;
              color: #16161d;
            }
          }

          .ant-collapse-header {
            background-color: #fff;

            &:hover {
              background-color: #e1e7ec;
            }
          }

          .ant-collapse-content-box {
            padding: 1rem;
            background-color: #f8f9fa;

            .ant-list {
              border: 0;

              .ant-spin-nested-loading {
                .ant-spin-container {
                  .ant-list-items {
                    .ant-list-item {
                      padding: 0 1rem;
                      border: 0;
                      align-items: flex-start;
                      flex-direction: column;
                      position: relative;

                      p {
                        margin: 0.5rem 0;
                        // text-transform: capitalize;
                      }

                      .keyterm--values,
                      .keyterm--helpers {
                        margin: 0.25rem 1rem;
                        color: #778899;
                      }

                      .keyterm--helpers {
                        cursor: pointer;

                        &.day_count {
                          text-transform: capitalize;
                        }
                      }

                      .ant-input,
                      .ant-select-selector {
                        margin: 0.5rem 0;
                        height: 42px !important;
                      }

                      .button-component {
                        height: 2rem;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        margin-bottom: 0.75rem;

                        button {
                          display: flex;
                          width: fit-content;
                        }
                      }

                      .pin-menu {
                        position: absolute;
                        top: 0.5rem;
                        right: 1rem;
                        cursor: pointer;

                        .pin-menu--icon {
                          width: 18px;
                          height: 18px;
                        }
                      }

                      &.selected {
                        background: #fff;
                        border-radius: 5px;
                        box-shadow: 0px 0px 10px #00263e14;
                      }

                      .ant-form {
                        .ant-row {
                          .ant-col {
                            .dropdown-field {
                              margin: 0.5rem 0 !important;

                              .ant-select {
                                .ant-select-selector {
                                  margin: 0;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          &.ant-collapse-item-active {
            .ant-collapse-header {
              background-color: #e1e7ec;
            }
          }
        }
      }
    }
  }
}

.pin-dropdown {
  .ant-dropdown-menu-item {
    &.blue-pin {
      color: #00629b;
    }

    &.green-pin {
      color: #12AD50;
    }

    &.red-pin {
      color: #ad1212;
    }

    .ant-dropdown-menu-title-content {
      img {
        margin: 0 0.5rem 0 0;
      }
    }
  }
}

.import-kt__modal.ant-modal {
  width: 750px !important;

  .ant-modal-body {
    .ant-row:first-child {
      border-bottom: 1px solid rgba(192, 192, 192, 0.534);
    }

    .search__container {
      padding: 1rem 0 0 0;

      .search--text {
        p {
          color: #16161d;
          font-size: 16px;
        }
      }

      .search--control {
        text-align: end;
      }
    }
  }
}

.panel-details {
  margin: 0 0.5rem;

  .panel-details--title {
    font-size: 12px;
    color: #778899;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .panel-details--value {
    font-size: 14px;
    font-weight: bold;
    color: #16161d;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // text-transform: capitalize;
  }
}

@keyframes slideDocumentHeaderPanelDown {
  from {
    opacity: 0;
    transform: translateY(-60px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDocumentHeaderPanelUp {
  from {
    opacity: 1;
    transform: translateY(0px);
  }

  to {
    opacity: 0;
    transform: translateY(-60px);
  }
}

.outliner-switch {
  margin-left: 10px;
  height: 20px;

  .ant-switch-handle {
    height: 16px;
  }
  &.ant-switch-checked {
    &:not(:disabled) {
      background-color: #00629b;
    }
  }
}

.ant-tabs {
  margin: 0 0 0;

  .ant-tabs-nav {
    margin: 0;

    .ant-tabs-nav-wrap {
      // padding: 0 0 0 1.5rem;

      .ant-tabs-nav-list {
        color: #778899;

        .ant-tabs-tab {
          font-size: 16px;

          &.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: #00629b;
          }
        }

        .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
          background-color: #00629b;
        }
      }
    }
  }
}

.text {
  padding-right: 2.5rem;
}