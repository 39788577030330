@import "variables";
@import "helpers";
@import "icons";

/* General component styles */
body {
  background-color: #FFFFFF;
  color: $primary-black;
}

body,
h1,
h2,
h3,
h4,
h5,
button,
input {
  font-family: 'Roboto', sans-serif !important;
}

.pin-modal-fake {
  display: none;
}

.invisible-content {
  display: none;
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

.rpv-highlight__selected-text {
  background-color: rgba($color: yellow, $alpha: 0.2) !important;
}

.mr-2 {
  margin-right: 0.25rem;
}

.ant-collapse-extra {
  float: unset !important;
  margin-left: 12px;
}

.site-collapse-custom-collapse {
  .ant-collapse-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}