@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?wb7zhl');
  src: url('fonts/icomoon.eot?wb7zhl#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?wb7zhl') format('truetype'),
    url('fonts/icomoon.woff?wb7zhl') format('woff'),
    url('fonts/icomoon.svg?wb7zhl#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-accept:before {
  content: "\e900";
  color: #778899;
}

.icon-accordion-close:before {
  content: "\e901";
  color: #778899;
}

.icon-accordion-open:before {
  content: "\e902";
  color: #778899;
}

.icon-add-dox-coins:before {
  content: "\e903";
  color: #778899;
}

.icon-add-tag:before {
  content: "\e904";
  color: #778899;
}

.icon-add:before {
  content: "\e905";
  color: #778899;
}

.icon-address:before {
  content: "\e906";
  color: #778899;
}

.icon-agreement:before {
  content: "\e907";
  color: #778899;
}

.icon-alert-triangle:before {
  content: "\e908";
  color: #778899;
}

.icon-audit-trail:before {
  content: "\e909";
  color: #778899;
}

.icon-back:before {
  content: "\e90a";
  color: #778899;
}

.icon-caret-down-1:before {
  content: "\e90b";
  color: #778899;
}

.icon-caret-down:before {
  content: "\e90c";
  color: #778899;
}

.icon-change-password:before {
  content: "\e90d";
  color: #778899;
}

.icon-close:before {
  content: "\e90e";
  color: #778899;
}

.icon-compare-agreement:before {
  content: "\e90f";
  color: #778899;
}

.icon-configure:before {
  content: "\e910";
  color: #778899;
}

.icon-copy-link:before {
  content: "\e911";
  color: #778899;
}

.icon-CP-broker:before {
  content: "\e912";
  color: #778899;
}

.icon-dashboard:before {
  content: "\e913";
  color: #778899;
}

.icon-date:before {
  content: "\e914";
  color: #778899;
}

.icon-delete:before {
  content: "\e915";
  color: #778899;
}

.icon-discussion-board:before {
  content: "\e916";
  color: #778899;
}

.icon-dislike:before {
  content: "\e917";
  color: #778899;
}

.icon-download:before {
  content: "\e918";
  color: #778899;
}

.icon-DOX-coin:before {
  content: "\e919";
  color: #778899;
}

.icon-edit-photo:before {
  content: "\e91a";
  color: #778899;
}

.icon-edit:before {
  content: "\e91b";
  color: #778899;
}

.icon-email:before {
  content: "\e91c";
  color: #778899;
}

.icon-excel---export .path1:before {
  content: "\e91d";
  color: rgb(33, 163, 102);
}

.icon-excel---export .path2:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(16, 124, 65);
}

.icon-excel---export .path3:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(51, 196, 129);
}

.icon-excel---export .path4:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(24, 92, 55);
}

.icon-excel---export .path5:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(16, 124, 65);
}

.icon-excel---export .path6:before {
  content: "\e922";
  margin-left: -1em;
  color: rgba(0, 0, 0, 0.09);
}

.icon-excel---export .path7:before {
  content: "\e923";
  margin-left: -1em;
  color: rgba(0, 0, 0, 0.2);
}

.icon-excel---export .path8:before {
  content: "\e924";
  margin-left: -1em;
  color: rgba(0, 0, 0, 0.2);
}

.icon-excel---export .path9:before {
  content: "\e925";
  margin-left: -1em;
  color: rgba(0, 0, 0, 0.2);
}

.icon-excel---export .path10:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(16, 124, 65);
}

.icon-excel---export .path11:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-export:before {
  content: "\e928";
  color: #778899;
}

.icon-filter .path1:before {
  content: "\e929";
  color: rgb(59, 119, 155);
}

.icon-filter .path2:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-fund:before {
  content: "\e92b";
  color: #778899;
}

.icon-global-search:before {
  content: "\e92c";
  color: #fbfbfc;
}

.icon-go:before {
  content: "\e92d";
  color: #778899;
}

.icon-help:before {
  content: "\e92e";
  color: #fbfbfc;
}

.icon-HFund-company:before {
  content: "\e92f";
  color: #778899;
}

.icon-info .path1:before {
  content: "\e930";
  color: rgb(205, 216, 223);
}

.icon-info .path2:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(91, 127, 149);
}

.icon-internal-users:before {
  content: "\e932";
  color: #778899;
}

.icon-key-contacts:before {
  content: "\e933";
  color: #778899;
}

.icon-key-terms:before {
  content: "\e934";
  color: #778899;
}

.icon-like:before {
  content: "\e935";
  color: #778899;
}

.icon-more:before {
  content: "\e936";
  color: #778899;
}

.icon-move-one-position-down:before {
  content: "\e937";
  color: #778899;
}

.icon-move-one-position-up:before {
  content: "\e938";
  color: #778899;
}

.icon-move-to-bottom:before {
  content: "\e939";
  color: #778899;
}

.icon-move-to-top:before {
  content: "\e93a";
  color: #778899;
}

.icon-normal-search:before {
  content: "\e93b";
  color: #778899;
}

.icon-notes:before {
  content: "\e93c";
  color: #778899;
}

.icon-notification-1:before {
  content: "\e93d";
  color: #778899;
}

.icon-notification:before {
  content: "\e93e";
  color: #fbfbfc;
}

.icon-partners:before {
  content: "\e93f";
  color: #778899;
}

.icon-PDF:before {
  content: "\e940";
  color: #e2574c;
}

.icon-phone:before {
  content: "\e941";
  color: #778899;
}

.icon-pin-indicator .path1:before {
  content: "\e942";
  color: rgb(0, 98, 155);
}

.icon-pin-indicator .path2:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(101, 170, 210);
}

.icon-pin-indicator .path3:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-pinned-tag:before {
  content: "\e945";
  color: #778899;
}

.icon-proceed:before {
  content: "\e946";
  color: #778899;
}

.icon-refresh:before {
  content: "\e947";
  color: #fafafb;
}

.icon-reject:before {
  content: "\e948";
  color: #778899;
}

.icon-remove-dox-coins:before {
  content: "\e949";
  color: #778899;
}

.icon-remove-1:before {
  content: "\e94a";
  color: #778899;
}

.icon-remove:before {
  content: "\e94b";
  color: #778899;
}

.icon-replace-current-version:before {
  content: "\e94c";
  color: #778899;
}

.icon-replicate:before {
  content: "\e94d";
  color: #778899;
}

.icon-report:before {
  content: "\e94e";
  color: #778899;
}

.icon-review-star:before {
  content: "\e94f";
  color: #ffc30b;
}

.icon-scorecard:before {
  content: "\e950";
  color: #778899;
}

.icon-Send:before {
  content: "\e951";
  color: #778899;
}

.icon-sort-column:before {
  content: "\e952";
  color: #8cacc9;
}

.icon-sorted-in-ascending---column .path1:before {
  content: "\e953";
  color: rgb(140, 172, 201);
}

.icon-sorted-in-ascending---column .path2:before {
  content: "\e954";
  margin-left: -0.61328125em;
  color: rgb(0, 98, 155);
}

.icon-sorted-in-asending .path1:before {
  content: "\e955";
  color: rgb(59, 119, 155);
}

.icon-sorted-in-asending .path2:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-sorted-in-descending---column .path1:before {
  content: "\e957";
  color: rgb(0, 98, 155);
}

.icon-sorted-in-descending---column .path2:before {
  content: "\e958";
  margin-left: -0.61328125em;
  color: rgb(140, 172, 201);
}

.icon-sorted-in-descending .path1:before {
  content: "\e959";
  color: rgb(59, 119, 155);
}

.icon-sorted-in-descending .path2:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-ticket:before {
  content: "\e95b";
  color: #778899;
}

.icon-undo:before {
  content: "\e95c";
  color: #778899;
}

.icon-upload-logo:before {
  content: "\e95d";
  color: #789;
}

.icon-upload:before {
  content: "\e95e";
  color: #778899;
}

.icon-view:before {
  content: "\e95f";
  color: #778899;
}