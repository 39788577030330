.ant-modal-root {
  position: relative;
}

.delete-alert__container {
  text-align: center;
  top: 25%;

  .ant-modal-content {
    padding-top: 5%;
    border-radius: 6px !important;
    border-top: 6px solid #d91616;
  }

  .delete-alert__icon {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #d91616;
    padding-top: 0.75rem;
    border-radius: 1.5rem;
    width: 3rem;
    height: 3rem;
    margin: auto;

    img {
      width: 20px;
      height: 24px;
    }
  }

  .delete-alert__logo {
    img {
      width: 130px;
      height: 130px;
    }
  }

  .delete-alert__title {
    font-size: 18px;
    font-weight: bold;
    color: #d91616;
  }

  .delete-alert__description {
    color: #16161d;
    font-size: 16px;
    font-weight: 500;
    margin: 20px 0;
  }

  .delete-alert__controllers {
    display: flex;
    justify-content: space-between;

    .button-component {
      margin: 10px;
    }
  }
}

.confirmation-alert__container {
  text-align: center;
  position: relative;

  .ant-modal-content {
    padding-top: 5%;
    border-radius: 6px !important;
    border-top: 6px solid #12ad50;
  }

  .confirmation-alert__icon {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #12ad50;
    padding-top: 0.75rem;
    border-radius: 1.5rem;
    width: 3rem;
    height: 3rem;
    margin: auto;
    box-shadow: 0px 10px 20px #00432133;

    img {
      width: 20px;
      height: 24px;
    }
  }

  .confirmation-alert__logo {
    img {
      width: 130px;
      height: 130px;
    }
  }

  .confirmation-alert__title {
    font-size: 18px;
    font-weight: bold;
    color: #12ad50;
  }

  .confirmation-alert__description {
    color: #16161d;
    font-size: 16px;
    font-weight: 500;
    margin: 20px 0;
  }

  .confirmation-alert__controllers {
    .button-component {
      margin: 10px;
    }

    .score-request--buttons {
      display: flex;

      .button-component {
        height: 40px;
      }
    }
  }
}

.reject-alert__container {
  text-align: center;
  position: relative;

  .ant-modal-content {
    padding-top: 5%;
    border-radius: 6px !important;
    border-top: 6px solid #d91616;
  }

  .reject-alert__icon {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #d91616;
    padding-top: 0.75rem;
    border-radius: 1.5rem;
    width: 3rem;
    height: 3rem;
    margin: auto;

    img {
      width: 20px;
      height: 24px;
    }
  }

  .reject-alert__logo {
    img {
      width: 130px;
      height: 130px;
    }
  }

  .reject-alert__title {
    font-size: 18px;
    font-weight: bold;
    color: #d91616;
  }

  .reject-alert__description {
    color: #16161d;
    font-size: 16px;
    font-weight: 500;
    margin: 20px 0;
  }

  .reject-alert__controllers {
    display: flex;
    justify-content: space-between;

    .button-component {
      margin: 10px;
    }
  }
}