.dropdown-field {
  position: relative;
  margin-top: 5% !important;
  margin: 0.5rem 0 !important;
  .ant-select {
    width: 100%;
  }

  .ant-select-selector {
    height: 42px !important;
    border-radius: 6px !important;
    border-color: #bac3cc !important;
    background-color: #fbfbfc !important;
    overflow-y: auto;
    text-transform: capitalize !important;
  }

  .ant-select-selector::-webkit-scrollbar {
    display: none;
  }

  .ant-select-single {
    .ant-select-selection-search {
      top: 10px !important;
    }

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      padding-top: 0.5rem !important;
    }
  }
}

.ant-select-dropdown {
  text-transform: capitalize;
}

.ant-select.ant-select-single.mobile-code {
  margin-top: -13%;
}

.field-label {
  position: absolute;
  top: 0px !important;
  left: 10px;
  z-index: 1;
  color: grey;
  background: #fff;
  padding: 0 3px;
  border-radius: 5px;
  font-size: 12px !important;
  animation: slideDropdownLabelUp 200ms linear forwards;
}

@keyframes slideDropdownLabelUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.dropdown__wrapper {
  // display: flex;
  // align-items: center;
  // width: 100%;
}
