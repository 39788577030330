@import "src/styles/variables";

.document {
    position: relative;
}

.rpv-core__viewer.rpv-core__viewer--light {
    position: relative !important;
    top: 0;
    left: 0;
}

#pdf-container {
    position: relative;
   
}

.pdf-outer-container {
    overflow: scroll;
    // height: 90vh;
}