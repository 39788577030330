.ui-modal {
  width: 35vw;
  margin: 2rem 0;

  .ui-modal__controllers {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0 0 auto !important;

    .button-component {
      width: 170px;
      margin-left: 18px !important;
    }
  }

  .ant-modal-content {
    border-radius: 6px;
  }

  .ant-modal-header {
    padding: 1.5rem;
    background: transparent linear-gradient(100deg, #00629b 0%, #00263e 100%) 0% 0% no-repeat padding-box;
    border-radius: 6px 6px 0 0;
    text-transform: capitalize;

    .ant-modal-title {
      margin-right: 20px;
      color: white;
    }
  }

  .ant-modal-close-x {
    svg {
      color: white;
      margin-top: 1.5rem;
    }
  }
}

.related-agreements-modal {
  width: 700px !important;

  .ant-modal-content {
    .ant-modal-body {
      padding: 0 24px 24px 24px;
    }
  }
}